import React from "react";
import { Outlet } from "react-router-dom";

const PublicRoute=(props) =>{
	
return <Outlet />

};

export default PublicRoute;
