import React from "react";

import UserOptions from "../../Profile/UserOptions";

const index = () => {
  return (
    <div className="buttons">
      {/* <div className="nav-item">Clients</div> */}
      <UserOptions />
    </div>
  );
};

export default index;
