export const firebaseConfig = {
  apiKey: "AIzaSyBqxWm0umMATGW2GCObXFcvwhAX_A7hCSU",
  authDomain: "ulu-storytelling-app-255f9.firebaseapp.com",
  databaseURL: "https://ulu-storytelling-app-255f9-default-rtdb.firebaseio.com",
  projectId: "ulu-storytelling-app-255f9",
  storageBucket: "ulu-storytelling-app-255f9.appspot.com",
  messagingSenderId: "779147159549",
  appId: "1:779147159549:web:8be602dd29cce22f779b94"
};

//export default firebaseSecondaryConfig;
//export default firebaseConfig;
